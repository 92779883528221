.back-to-home-button {
    position: relative;
    bottom: 2px;
    margin-left: 20px;
    border-radius: 100px;
}

.back-to-home-button:hover {
    cursor: pointer;
}

.eraList {
    width: 500px;
}

/* The actual timeline (the vertical ruler) */
.timeline {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
}

/* The actual timeline (the vertical ruler) */
.timeline::after {
    content: '';
    position: absolute;
    background-color: #800015;
    width: 6px;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -3px;
}

/* Container around content */
.container-left {
    padding: 10px 40px;
    position: relative;
    background-color: inherit;
    width: 50%;
}

.container-right {
    padding: 10px 40px;
    position: relative;
    background-color: inherit;
    width: 50%;
}

/* The circles on the timeline */
.container-left::after {
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    right: -12.5px;
    background-color: white;
    /* TODO: use theme properly for border */
    border: 4px solid #800015;
    top: 15px;
    border-radius: 50%;
    z-index: 1;
}

.container-right::after {
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    margin-left: 3.25px;
    background-color: white;
    /* TODO: use theme properly for border */
    border: 4px solid #800015;
    top: 15px;
    border-radius: 50%;
    z-index: 1;
}

/* Place the container to the left */
.container-left {
    left: 0;
}

/* Place the container to the right */
.container-right {
    left: 50%;
}

/* Add arrows to the left container (pointing right) */
.container-left::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 18px;
    width: 0;
    z-index: 1;
    right: 30px;
    border: medium solid white;
    border-width: 10px 0 10px 10px;
    border-color: transparent transparent transparent #800015;
}

/* Add arrows to the right container (pointing left) */
.container-right::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 18px;
    width: 0;
    z-index: 1;
    left: 30px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent #800015 transparent transparent;
}

/* Fix the circle for containers on the right side */
.container-right::after {
    left: -16px;
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 900px) {
  /* Place the timelime to the left */
  .timeline::after {
    left: 31px;
  }

  /* Full-width containers */
  .container-left, .container-right {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
  }

  /* Make sure that all arrows are pointing leftwards */
  .container-left::before, .container-right::before {
    left: 60px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent #800015 transparent transparent;
  }

  /* Make sure all circles are at the same spot */
  .container-left::after, .container-right::after {
    left: 15px;
  }

  /* Make all right containers behave like the left ones */
  .container-right {
    left: 0%;
  }
}
.add-new-era-container {
  margin-top: 20px;
  text-align: center;
}
