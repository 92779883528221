.content-container {
    padding: 10px;
    overflow: auto;
    max-height: 600px;
}

@media screen and (max-width: 1608px) {
    .content-container {
        max-height: 560px;
    }
}

@media screen and (max-width: 1157px) {
    .content-container {
        max-height: 510px;
    }
}

.title {
    margin-top: 3px;
    margin-bottom: 8px;
}

.content-details-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
}

.content-details-misc-container {
    display: inline-block;
    width: 35%;
    text-align: left;
    font-size: 14px;
}

@media screen and (max-width: 1608) {
    .content-details-container {
        display: block;
        text-align: center;
    }

    .content-details-misc-container {
        width: 100%;
        text-align: center;
    }
}

.content-details-misc-container p {
    margin-top: 0px;
    margin-bottom: 5px;
}

.pane {
    overflow: scroll;
    height: 700px;
}

.button-container {
    border-top: 2px solid #c3c3c3;
    padding-top: 5px;
}