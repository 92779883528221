.search-toolbar-inner-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    float: right;
    margin-right: 50px;
    font-size: 14px;
    bottom: 1px;
    position: relative;
}
.category-select {
    width: 200px;
}
.category-select-label {
    margin-right: 5px;
    position: relative;
    bottom: 1px;
}
.search-autocomplete-label {
    margin-left: 10px;
    margin-right: 5px;
    position: relative;
    bottom: 1px;
}
.add-anime-button {
    position: absolute;
    left: 75px;
    padding-left: 10px;
    padding-right: 10px;
    /* line-height: 80px; */
    border-radius: 25px;
}
.add-anime-button:hover {
    cursor: pointer;
}

.add-anime-button:active {
    background-color: darkgray;
    transition: 0s;
  }